export const articles = [
    {
      id: 1,
      title: "What is Endodontics – Root Canal?",
      description:
        `Endodontics is commonly called "killing the nerve".`, 
      date: "2024-01-12",
      href: "/blog/article",
      image: "https://www.clinicaveronicagil.com/wp-content/uploads/2023/06/erosion-dental.jpg"
    },
    {
      id: 2,
      title:
        "Secretos Revelados: Descubre el Universo a Través de Historias Fascinantes",
      description: "Sumérgete en historias fascinantes y amplía tu horizonte.",
      date: "2021-08-21",
      href: "/blog/document",
      image: "https://source.unsplash.com/random"
    },
    {
      id: 3,
      title: "Mundos Mágicos: Sueños Hechos Realidad en Lugares Asombrosos",
      description:
        "Descubre secretos sorprendentes en cada rincón del universo.",
      date: "2021-08-21",
      href: "/blog/hosting",
      image: "https://source.unsplash.com/random"
    },
    {
      id: 4,
      title: "Pensamientos Diversos: Explorando la Riqueza de las Ideas",
      description:
        "Viaja a lugares mágicos donde los sueños se hacen realidad.",
      date: "2021-08-21",
      href: "/blog/wrestling",
      image: "https://source.unsplash.com/random"
    },
    {
      id: 5,
      title: "Desafíos Emocionantes: Aventuras en Lugares Inexplorados",
      description:
        "Explora la diversidad de pensamientos y vive experiencias únicas.",
      date: "2021-08-21",
      href: "/blog/manifest",
      image: "https://source.unsplash.com/random"
    },
    {
      id: 6,
      title: "Conexiones Inolvidables: Compartiendo Pasiones y Momentos Únicos",
      description:
        "Aventúrate en mundos desconocidos llenos de emocionantes desafíos.",
      date: "2021-08-21",
      href: "/blog/tradecenter",
      image: "https://source.unsplash.com/random"

    },
  ];