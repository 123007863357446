import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import { articles } from "../Components/data";
function BlogPage() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        articles.map((article) =>
          fetch(`https://source.unsplash.com/random?id=${article.id}`).then(
            (response) => response.url
          )
        )
      );
      setImages(images);
    };

    fetchImages();
  }, []);

  return (
    <main className="h-full bg-blue-gray-800">
      <div className="text-black mx-72">
        <section>
          <div className="flex flex-col items-center justify-center h-screen gap-8 text-white">
            <Typography color="blueGray" className="text-4xl font-bold">
              "Journey Through Words: Exploring the Boundless Universe of
              Blogging"
            </Typography>
            <Typography color="blueGray" className="text-lg font-medium">
              "Dive into a world of insights, stories, and discoveries. Join us
              on a captivating journey through the art of blogging, where words
              come alive and ideas flourish. Embrace the power of storytelling
              and connect with a community of passionate minds."
            </Typography>
            <Button variant="filled">Button</Button>
          </div>
        </section>
        <section>
          {articles.map((article) => (
            <div
              key={article.id}
              className="grid grid-cols-1 gap-4 py-8 text-white bg-blue-gray-800 md:grid-cols-2"
            >
              <img
                className="object-cover rounded-lg object-center w-[800px] h-72"
                src={article.image}
                // src={[images[index]]}
                alt="natureImage"

              />{" "}
              <div className="mx-8">
                <p color="blueGray" className="text-4xl font-bold">
                  {article.title}
                </p>
                <p color="blueGray" className="py-6 text-lg font-medium">
                  {article.description}
                </p>
                <span
                  color="blueGray"
                  className="text-lg font-medium text-blue-gray-400"
                >
                  {article.date}
                </span>
                <a className="flex justify-start py-4" href={article.href}>
                  <Button color="blue" variant="filled">
                    Button
                  </Button>
                </a>
              </div>
            </div>
          ))}
        </section>
      </div>
    </main>
  );
}

export default BlogPage;
