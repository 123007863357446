import React from 'react'
import TreatmentDinamicPage from './Pages/TreatmentDinamicPage'
function TreatmentDinamic() {
  return (
    <div>
        <TreatmentDinamicPage />
    </div>
  )
}

export default TreatmentDinamic