import React, { useState, useEffect } from "react";
import { Link } from "react-scroll"; // Importa Link desde react-scroll
import logo from "../img/logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useLanguage } from "./languageContext";
import USA from "../img/USA.png";
import MEXICO from "../img/Mexico.png";
import { translations } from "../Components/translations"; // Asegúrate de importar el hook

function Navbar() {
  const { language, setLanguage } = useLanguage();

  const {
    home,
    mission,
    solutions,
    treatments,
    booking,
    contact,
    sendMessage,
  } = translations[language].navbar;

  const [showMenu, setShowMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`bg-white p-4 ${isSticky ? "sticky top-0 z-50" : ""}`}>
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <img
            src={logo}
            alt="Logo"
            className="flex justify-start w-8 h-8 ml-4 mr-2"
          />
          <div className="flex items-center justify-center w-full lg:w-center">
            {/* Reemplaza los href con Link de react-scroll */}
            <Link
              to="Home"
              smooth={true}
              duration={500}
              className="hidden text-black hover:text-green-300 lg:inline-block"
            >
              {home}
            </Link>
            <Link
              to="Mission"
              smooth={true}
              duration={500}
              className="hidden ml-4 text-black hover:text-green-300 lg:inline-block"
            >
              {mission}
            </Link>
            <Link
              to="Solutions"
              smooth={true}
              duration={500}
              className="hidden ml-4 text-black hover:text-green-300 lg:inline-block"
            >
              {solutions}
            </Link>
            <a
              href="/treatment"
              className="hidden ml-4 text-black hover:text-green-300 lg:inline-block"
            >
              {treatments}
            </a>
            <Link
              to="Booking"
              smooth={true}
              duration={500}
              className="hidden ml-4 text-black hover:text-green-300 lg:inline-block"
            >
              {booking}
            </Link>
            <a
              href="/contact"
              className="hidden ml-4 text-black hover:text-green-300 lg:inline-block"
            >
              {contact}
            </a>
            {/* <a
              href="/blog"
              className="hidden ml-4 text-black hover:text-green-300 lg:inline-block"
            >
              Blog
            </a> */}
            <a
              href="https://api.whatsapp.com/send?phone=+16195861436&text=Hola,%20visite%20su%20pagina%20y%20quisiera%20conocer%20más%20sobre%20sus%20servicios%20dentales"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="hidden lg:inline-block select-none items-center gap-4 ml-16 mr-4 rounded-lg border border-green-700 py-1 px-2 text-center align-middle font-sans text-sm font-bold uppercase text-blue-gray-500 transition-all hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                data-ripple-dark="true"
              >
                <div className="flex justify-center items-center gap-2">
                  <FontAwesomeIcon icon={faWhatsapp} className="w-8 h-8" />
                  {sendMessage}
                </div>
              </button>
            </a>
            <div className="w-36">
              <div className="relative w-36">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="block w-full py-2 pl-8 pr-3 text-base border border-green-700 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  {language === "en" ? "English" : "Español"}
                  <span className="absolute left-2">
                    <img
                      src={language === "en" ? USA : MEXICO}
                      alt="flag"
                      className="w-5 h-5"
                    />
                  </span>
                </button>

                {isOpen && (
                  <div className="absolute w-full mt-1 bg-white border border-black rounded-md">
                    <div
                      onClick={() => {
                        setLanguage("en");
                        setIsOpen(false);
                      }}
                      className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      <img src={USA} alt="USA Flag" className="w-5 h-5 mr-2" />
                      English
                    </div>
                    <div
                      onClick={() => {
                        setLanguage("es");
                        setIsOpen(false);
                      }}
                      className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      <img
                        src={MEXICO}
                        alt="Mexico Flag"
                        className="w-5 h-5 mr-2"
                      />
                      Español
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ml-2 lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-black cursor-pointer hover:text-green-300 focus:outline-none"
            >
              {showMenu ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </button>
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="bg-white border-t border-gray-200 lg:hidden">
          <ul className="p-4 ml-7">
            {/* Reemplaza los href con Link de react-scroll */}
            <li>
              <Link
                to="Home"
                smooth={true}
                duration={500}
                className="text-black hover:text-green-300"
              >
                {home}
              </Link>
            </li>
            <li>
              <Link
                to="Mission"
                smooth={true}
                duration={500}
                className="text-black hover:text-green-300"
              >
                {mission}
              </Link>
            </li>
            <li>
              <Link
                to="Solutions"
                smooth={true}
                duration={500}
                className="text-black hover:text-green-300"
              >
                {solutions}
              </Link>
            </li>
            <li className="lg:hidden">
              <a href="/treatment" className="text-black hover:text-green-300">
                {treatments}
              </a>
            </li>
            <li className="lg:hidden">
              <Link
                to="Booking"
                smooth={true}
                duration={500}
                className="text-black hover:text-green-300"
              >
                {booking}
              </Link>
            </li>
            <li className="lg:hidden">
              <a
                href="/contact"
                className=" text-black text-center hover:text-green-300 lg:inline-block"
              >
                {contact}
              </a>
            </li>
            <li className="lg:hidden">
              <a
                className="text-black hover:text-green-300 lg:inline-block"
                href="https://api.whatsapp.com/send?phone=+16195861436&text=Hola,%20visite%20su%20pagina%20y%20quisiera%20conocer%20más%20sobre%20sus%20servicios%20dentales"
                target="_blank"
                rel="noreferrer"
              >
                {sendMessage}
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
