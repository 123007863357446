import React from 'react'
import Blog from './Pages/BlogDinamicPage'

function BlogDinamic() {
  return (
    <div>
      <Blog />
    </div>
  )
}

export default BlogDinamic