import React, { useState } from "react";
import svg from "../img/Imagen profesionalismo.svg";
import svg2 from "../img/smile.svg";
import svg3 from "../img/experience.svg";
import svg4 from "../img/Technology.svg";
import { Fade } from "react-awesome-reveal";
import { useSpring } from "react-spring";
import { useLanguage } from "../Components/languageContext";
import { translations } from "../Components/translations";

function MisionSection() {
  const { language } = useLanguage();

  const [showMissionContent] = useState(false);

  const missionHeaderSpring = useSpring({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: {
      opacity: showMissionContent ? 1 : 0,
      transform: showMissionContent ? "translateY(0)" : "translateY(-20px)",
    },
    config: { duration: 1000 },
  });

  return (
    <section id="Mission" className="py-24 bg-gray-100">
      <div className="container mx-auto text-center">
        <Fade bottom>
          <p className="mb-10 text-4xl font-bold text-black">
            {translations[language].mission.title}
          </p>
        </Fade>
        <Fade bottom>
          <p
            style={missionHeaderSpring}
            className="text-[#9DC216] text-3xl font-bold mb-4 mr-4 ml-4"
          >
            {translations[language].mission.subtitle1}
          </p>
        </Fade>
        <Fade bottom>
          <p
            style={missionHeaderSpring}
            className="text-[#9DC216] text-3xl  mb-4"
          >
            {translations[language].mission.subtitle2}
          </p>
        </Fade>
        <Fade bottom>
          <div className="grid grid-cols-1 gap-4 py-8 sm:grid-cols-2">
            <div className="flex flex-row items-start">
              <img
                src={svg}
                className="w-24 h-24 mr-4 sm:h-32 sm:w-32"
                alt="Imagen"
              />
              <div className="flex flex-col">
                <h1
                  style={missionHeaderSpring}
                  className="px-4 text-xl font-bold text-left text-black sm:text-2xl"
                >
                  {translations[language].mission.professionalism.title}
                </h1>
                <h2 style={missionHeaderSpring} className="px-4 text-justify">
                  {translations[language].mission.professionalism.content}
                </h2>
              </div>
            </div>
            <div className="flex flex-row items-start">
              <img
                src={svg2}
                className="w-24 h-24 mr-4 sm:h-32 sm:w-32"
                alt="Imagen"
              />
              <div className="flex flex-col">
                <h1
                  style={missionHeaderSpring}
                  className="px-4 text-xl font-bold text-left text-black sm:text-2xl"
                >
                  {translations[language].mission.smile.title}
                </h1>
                <h2 style={missionHeaderSpring} className="px-4 text-justify">
                  {translations[language].mission.smile.content}
                </h2>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="grid grid-cols-1 gap-4 py-4 sm:grid-cols-2">
            <div className="flex flex-row items-start">
              <img
                src={svg4}
                className="w-24 h-24 mr-4 sm:h-32 sm:w-32"
                alt="Imagen"
              />
              <div className="flex flex-col">
                <h1
                  style={missionHeaderSpring}
                  className="px-4 text-xl font-bold text-left text-black sm:text-2xl"
                >
                  {translations[language].mission.technology.title}
                </h1>
                <h2 style={missionHeaderSpring} className="px-4 text-justify">
                  {translations[language].mission.technology.content}
                </h2>
              </div>
            </div>
            <div className="flex flex-row items-start">
              <img
                src={svg3}
                className="w-24 h-24 mr-4 sm:h-32 sm:w-32"
                alt="Imagen"
              />
              <div className="flex flex-col">
                <h1
                  style={missionHeaderSpring}
                  className="px-4 text-xl font-bold text-left text-black sm:text-2xl"
                >
                  {translations[language].mission.experience.title}
                </h1>
                <h2 style={missionHeaderSpring} className="px-4 text-justify">
                  {translations[language].mission.experience.content}
                </h2>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default MisionSection;
