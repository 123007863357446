import React, { useState, useEffect } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  Calendar,
  utils,
  Day,
  CalendarDigit,
} from "react-modern-calendar-datepicker";
import { motion } from "framer-motion";
import axios from "axios";
import { useLanguage } from "../Components/languageContext";
import { translations } from "../Components/translations";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import diente from "../img/diente.svg";
import correo from "../img/correo.svg";
import pin from "../img/pin.svg";
import reloj from "../img/reloj.svg";
import MyPDFDocument from "../Components/pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { format } from "date-fns";

const CalendarReact: React.FC = () => {
  interface BookedHour {
    date: string;
    hour: string;
  }
  type DateObject = {
    year: number;
    month: number;
    day: number;
  };
  const [open, setOpen] = React.useState(false);
  const { language } = useLanguage();
  const [selectedDay, setSelectedDay] = useState<Day | null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [bookedHours, setBookedHours] = useState<BookedHour[]>([]);
  const [resetSelect, setResetSelect] = useState(false); // Estado para reiniciar el select
  const hours = Array.from({ length: 10 }, (_, i) => 8 + i);
  const allHoursBooked = hours.every((hour) => {
    const time24 = hour < 10 ? `0${hour}:00:00` : `${hour}:00:00`;
    const date = selectedDay
      ? `${selectedDay.year}-${
          selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month
        }-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`
      : "";
    return bookedHours.some((bh) => bh.date === date && bh.hour === time24);
  });
  const title = allHoursBooked
    ? translations[language].calendar.DayNotAvailable
    : translations[language].calendar.DayAvailable;
  const myCustomLocale = {
    months: translations[language].calendar.months,

    // week days by order
    weekDays: [
      {
        name: translations[language].calendar.calendarDays[0],
        short: translations[language].calendar.Days[0], // displayed at the top of days' rows
        isWeekend: true, // is it a formal weekend or not?
      },
      {
        name: translations[language].calendar.calendarDays[1],
        short: translations[language].calendar.Days[1], // displayed at the top of days' rows
      },
      {
        name: translations[language].calendar.calendarDays[2],
        short: translations[language].calendar.Days[2], // displayed at the top of days' rows
      },
      {
        name: translations[language].calendar.calendarDays[3],
        short: translations[language].calendar.Days[3], // displayed at the top of days' rows
      },
      {
        name: translations[language].calendar.calendarDays[4],
        short: translations[language].calendar.Days[4], // displayed at the top of days' rows
      },
      {
        name: translations[language].calendar.calendarDays[5],
        short: translations[language].calendar.Days[5], // displayed at the top of days' rows
      },
      {
        name: translations[language].calendar.calendarDays[6],
        short: translations[language].calendar.Days[6], // displayed at the top of days' rows
        isWeekend: false,
      },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject: DateObject): DateObject {
      return gregorainTodayObject;
    },

    toNativeDate(date: DateObject): Date {
      return new Date(date.year, date.month - 1, date.day);
    },

    getMonthLength(date: DateObject): number {
      return new Date(date.year, date.month, 0).getDate();
    },

    transformDigit(digit: number): number {
      return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isNumeric = /^\d+$/.test(phone);

    if (!isNumeric || phone.length !== 10) {
      alert(
        "El teléfono debe contener solo números y tener exactamente 10 dígitos"
      );
      return;
    }

    let timeIn24HourFormat = parseInt(selectedTime);
    if (timeIn24HourFormat >= 1 && timeIn24HourFormat <= 6) {
      timeIn24HourFormat += 12;
    }

    const BookingData = {
      name,
      email,
      phone,
      date: selectedDay
        ? `${selectedDay.year}-${selectedDay.month}-${selectedDay.day} ${timeIn24HourFormat}:00:00`
        : "",
    };

    try {
      const response = await axios.post(
        "https://reformadental.com/guardar-datos",
        BookingData
      );
      console.log(response);
      setResetSelect((prevState) => !prevState);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getBookedHours() {
      try {
        const response = await axios.get(
          "https://reformadental.com/fechas-seleccionadas"
        );
        setBookedHours(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    getBookedHours();
  }, [resetSelect]);

  const closeButton = () => {
    setOpen(!open);
    setName("");
    setEmail("");
    setPhone("");
    setSelectedTime("");
  };

  return (
    <div className="flex justify-center xl:flex-row flex-col items-center gap-2">
      <Calendar
        value={selectedDay}
        colorPrimary="#EC4988"
        minimumDate={utils("en").getToday()}
        onChange={(value: Day) => setSelectedDay(value)}
        calendarClassName="border-2 border-gray-800 rounded-xl"
        locale={{
          ...myCustomLocale,
          transformDigit: (digit: CalendarDigit) => digit,
        }}
        shouldHighlightWeekends
      />
      {selectedDay && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <form
            onSubmit={handleSubmit}
            className="flex gap-4 flex-col items-center"
          >
            <input
              type="text"
              className="border-2 border-gray-300 p-2 rounded-md text-center focus:outline-none w-64"
              value={`${selectedDay.month}/${selectedDay.day}/${selectedDay.year}`}
              readOnly
            />
            <select
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              required
              className="border-2 border-gray-300 p-2 rounded-md text-center focus:outline-none w-64"
            >
              <option value="">{title}</option>
              {hours.map((hour) => {
                const currentHour = parseInt(format(new Date(), "H")); // Obtiene la hora actual en formato 24 horas
                const time =
                  hour <= 11
                    ? `${hour}:00 AM`
                    : hour === 12
                    ? `12:00 PM`
                    : `${hour - 12}:00 PM`;
                const time24 = hour < 10 ? `0${hour}:00:00` : `${hour}:00:00`; // Asegúrate de que la hora esté formateada como HH:00:00
                const date = selectedDay
                  ? `${selectedDay.year}-${
                      selectedDay.month < 10
                        ? "0" + selectedDay.month
                        : selectedDay.month
                    }-${
                      selectedDay.day < 10
                        ? "0" + selectedDay.day
                        : selectedDay.day
                    }`
                  : ""; // Asegúrate de que la fecha esté formateada como YYYY-MM-DD
                const selectedDate = new Date(
                  selectedDay.year,
                  selectedDay.month - 1,
                  selectedDay.day
                );
                if (
                  selectedDate.getDay() === 0 || // Si el día seleccionado es domingo
                  bookedHours.some(
                    (bh) => bh.date === date && bh.hour === time24
                  ) ||
                  (selectedDay.year === new Date().getFullYear() &&
                    selectedDay.month === new Date().getMonth() + 1 &&
                    selectedDay.day === new Date().getDate() &&
                    hour <= currentHour)
                ) {
                  // Esta hora ya está reservada para el día seleccionado, así que no la mostramos
                  return null;
                }
                return (
                  <option key={hour} value={time}>
                    {time}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              className="border-2 border-gray-300 p-2 rounded-md text-center focus:outline-none w-64"
              placeholder={translations[language].booking.placeholderName}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              className="border-2 border-gray-300 p-2 rounded-md text-center focus:outline-none w-64"
              placeholder={translations[language].booking.placeholderEmail}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="tel"
              className="border-2 border-gray-300 p-2 rounded-md text-center focus:outline-none w-64"
              placeholder={translations[language].booking.placeholderPhone}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              maxLength={10}
            />
            <button
              className="bg-[#87D717] text-white p-2 rounded-md w-64 focus:outline-none hover:bg-[#89ce2a] transition duration-300 ease-in-out"
              type="submit"
            >
              {translations[language].booking.sectionTitle}
            </button>
          </form>
          <Dialog open={open} handler={handleSubmit}>
            <DialogHeader className="flex justify-center text-xl">
              {translations[language].booking.modalTitle}
            </DialogHeader>
            <DialogBody
              divider
              className="mx-auto md:mx-16 font-poppins font-medium"
            >
              <div className="text-center">
                <span className="text-[#565656] font-bold">
                  {translations[language].booking.modalBody1}
                </span>
                <h6 className="text-[#565656]">
                  {translations[language].booking.modalBody2}
                </h6>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row items-center gap-2">
                  <img src={correo} className="w-16" alt={correo} />
                  <h3 className="text-[#565656]">
                    {translations[language].booking.email}{" "}
                    <span className="text-[#565656] text-sm sm:text-base">
                      {email}
                    </span>
                  </h3>
                </div>{" "}
                <div className="flex flex-row items-center gap-2">
                  <img src={reloj} className="w-16" alt={reloj} />
                  <h3 className="text-[#565656]">
                    {translations[language].stripe.appointment}:{" "}
                    <span className="text-[#74af50]">
                      {selectedDay
                        ? new Date(
                            selectedDay.year,
                            selectedDay.month - 1,
                            selectedDay.day
                          ).toLocaleDateString(
                            translations[language].booking.data,
                            {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                      -{selectedTime}{" "}
                    </span>
                  </h3>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <img src={diente} className="w-16" alt={diente} />
                  <h3 className="text-[#565656]">
                    {translations[language].booking.modalEvent}
                  </h3>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <img src={pin} className="w-16" alt={pin} />
                  <h3 className="text-[#565656]">
                    {translations[language].booking.modalLocation}
                  </h3>
                </div>
              </div>
            </DialogBody>
            <DialogFooter>
              <div className="flex gap-2">
                <PDFDownloadLink
                  document={
                    <MyPDFDocument
                      email={`${translations[language].booking.email} ${email}`}
                      date={`${translations[language].booking.time}
                        ${
                          selectedDay
                            ? new Date(
                                selectedDay.year,
                                selectedDay.month - 1,
                                selectedDay.day
                              ).toLocaleDateString(
                                translations[language].booking.data,
                                {
                                  weekday: "long",
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""
                        }`}
                      time={`${translations[language].booking.modalTime}: ${selectedTime}`}
                      modalEvent={translations[language].booking.modalEvent}
                      modalLocation={
                        translations[language].booking.modalLocation
                      }
                    />
                  }
                  fileName={`Appoitment_${name}.pdf`}
                >
                  {({ loading }) => (
                    <button className="bg-[#87d717] focus:outline-none hover:bg-[#76bd4a] text-white font-bold px-12 py-2 rounded-2xl">
                      {loading
                        ? translations[language].stripe.loading
                        : translations[language].stripe.download}
                    </button>
                  )}
                </PDFDownloadLink>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-12 py-2 rounded-2xl"
                  onClick={closeButton}
                >
                  <span>{translations[language].booking.confirm}</span>
                </button>
              </div>
            </DialogFooter>
          </Dialog>
        </motion.div>
      )}
    </div>
  );
};

export default CalendarReact;
