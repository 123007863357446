import React from "react";
import Calendar from "../Components/calendar.tsx";
import { font } from "../Components/Images";
import { useLanguage } from "../Components/languageContext";
import { translations } from "../Components/translations";
import quaote from "../img/comilla.svg";
import marker from "../img/marker.svg";

function Booking() {
  const { language } = useLanguage();

  return (
    <main>
      <section id="Booking" className="bg-[#FFFF] py-8 lg:py-24">
        <div className="container mx-auto text-center">
          <h1 className="mb-4 text-4xl font-bold text-black lg:text-5xl">
            {translations[language].booking.sectionTitle}
          </h1>
          <p className="text-[#9DC216] text-2xl lg:text-3xl py-4">
            {translations[language].booking.subTitle}
          </p>
          <div className="grid grid-cols-1 gap-4 py-4 lg:grid-cols-2 lg:py-8">
            <div className="flex flex-col lg:mb-0">
              <img
                src={font}
                alt="dental-person"
                className="h-auto mx-auto mb-8"
              />
            </div>
            <div>
              <div className="flex flex-col px-4 bg-[#f9fdf3]">
                <h1 className="font-medium px-6 py-4 text-2xl text-black lg:text-start">
                  {translations[language].booking.createAppointment}
                </h1>
                <div className="flex items-center justify-start lg:justify-start mb-4 ml-4 mr-4">
                  <img src={marker} className="w-12 h-12" alt="icon-marcador" />
                  <h3 className="text-start">
                    {translations[language].booking.address}
                  </h3>
                </div>
                <div className="flex items-center justify-center lg:justify-start mb-4 ml-4 mr-4">
                  <img src={quaote} className="w-12 h-12" alt="icon-comillas" />
                  <h3 className="font-bold">
                    {translations[language].booking.pickAppointment}
                  </h3>
                  <img
                    src={quaote}
                    className="w-12 h-12 transform rotate-180"
                    alt="icon-comillas"
                  />
                </div>
                <div className="mb-4">
                  <h3 className="flex justify-center lg:justify-start px-8 text-[#74af50]">
                    {translations[language].booking.timeZone}
                  </h3>
                </div>
                <Calendar />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Booking;
