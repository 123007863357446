import React, { useState } from 'react'
import { Fade } from "react-awesome-reveal";
import { horario } from "../Components/Images";
import { useSpring } from 'react-spring';
import { useLanguage } from '../Components/languageContext';
import { translations } from '../Components/translations'

function SolutionSection() {


  const { language } = useLanguage();

  const [showMissionContent] = useState(false);


  const missionHeaderSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: showMissionContent ? 1 : 0, transform: showMissionContent ? 'translateY(0)' : 'translateY(-20px)' },
    config: { duration: 1000 },
  });
  
  return (
    <section id="Solutions" className="bg-[#F5FFE5] py-24">
    <div
      style={missionHeaderSpring} className="container flex justify-center mx-auto">
      <img src={horario} />
    </div>
    <div className="container py-8 mx-auto text-center md:py-16">
      <Fade bottom>
        <h3 className="px-4 mb-4 text-lg text-center text-gray-500 md:px-24 md:text-2xl">
        {translations[language].solutions.description1}
        </h3>
      </Fade>
      <Fade delay={700}>
        <a href='#Booking'>
          <button className="bg-[#87D717] hover:bg-[#95F50E] text-lg md:text-xl text-white font-bold py-2 md:py-3 px-12 md:px-20 mt-4 md:mt-5 rounded-full">
          {translations[language].home.buttonText}
          </button>
        </a>
      </Fade>
      <Fade bottom>
        <h3 className="py-4 mb-4 text-lg text-center text-gray-500 md:py-8 md:text-2xl">
        {translations[language].solutions.description2}
        </h3>
      </Fade>
      <Fade bottom>
        <h1 className="px-4 py-8 mb-4 text-3xl font-bold text-center text-black md:text-4xl">
        {translations[language].solutions.title}
        </h1>
      </Fade>
      <Fade bottom>
        <h2 className="text-center py-2 text-[#9DC216] font-bold text-xl md:text-2xl ml-2 mr-2">
        {translations[language].solutions.affirmation1}
        </h2>
      </Fade>
      <Fade bottom>
        <h3 className="text-center py-2 text-[#9DC216] text-xl md:text-2xl">
        {translations[language].solutions.affirmation2}
        </h3>
      </Fade>
    </div>
  </section>  )
}

export default SolutionSection