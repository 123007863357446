import React from 'react'
import {
    Collapse
} from "@material-tailwind/react";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from '../Components/languageContext';
import { translations } from '../Components/translations';

function ContactSection() {

    const { language } = useLanguage();
    const [open, setOpen] = React.useState(false);
    const toggleOpen = () => setOpen((cur) => !cur);
    return (
        <section id='Contact' className="bg-[#F5FFE5] py-24">
            <div className="container mx-auto">
            <h1 className="mb-8 text-4xl font-bold text-center text-black">{translations[language].contact.title}</h1>
            <h2 className="mb-8 text-3xl text-center text-black">
                    {translations[language].contact.subtitle}
                </h2>
                <div className='flex justify-center'>
                    <button
                        className="flex gap-3 rounded-lg border border-black py-3.5 px-7 text-center align-middle font-sans text-sm font-bold uppercase text-black transition-all hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        data-ripple-dark="true"
                        onClick={toggleOpen}
                    >
                        <FontAwesomeIcon icon={faLocationDot} fade style={{ color: "#e63d3d", }} />
                        {translations[language].contact.buttonLabel}
                    </button>
                </div>
                <Collapse open={open}>
                    <div className="relative h-0 mt-4" style={{ paddingBottom: "56.25%" }}>
                        <iframe
                            className="absolute top-0 left-0 w-full h-full"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.008303115534!2d-117.03663105457193!3d32.52592519987922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d9499b13b355e9%3A0x33f0692b196212e5!2sReforma%20Dental!5e0!3m2!1ses!2smx!4v1696869140483!5m2!1ses!2smx"
                            title="Ubicación de tu empresa en Google Maps"
                            allowFullScreen
                        ></iframe>
                    </div>
                </Collapse>
            </div>
        </section>)
}

export default ContactSection