import React from 'react'
import ContactPage from './Pages/ContactPage'

function Contact() {
  return (
    <div>
      <ContactPage />
    </div>
  )
}

export default Contact