import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { products } from "../Components/products";
import logo from "../img/logo.webp";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import mexico from "../img/Mexico.png";
import usa from "../img/USA.png";
function TreatmentDinamic() {
  const [language, setLanguage] = useState('en'); // Estado para el idioma actual

  const toggleLanguage = () => { // Función para cambiar el idioma
    setLanguage(language === 'es' ? 'en' : 'es');
  };

  const { id } = useParams();
  const product = products.find(
    (product) => product.link === `/treatment/${id}`
  );

  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen flex-col gap-8">
        <h1 className="font-poppins text-6xl font-medium text-green-500">
          Error 404
        </h1>
        <a href="/treatment">
          <button className="bg-green-500 border rounded-xl px-4 py-2 hover:bg-green-700 shadow-xl shadow-green-100 text-white">
            Return to Home
          </button>
        </a>
      </div>
    );
  }

  return (
    <main className="mx-auto flex items-center justify-center flex-col p-12">
      <div className="flex justify-center items-center gap-4">
      <img src={language === 'es' ? mexico : usa} alt="language" className="w-16" />
      <button onClick={toggleLanguage} className=" bg-green-500 hover:bg-green-700 border rounded-lg px-4 text-white">
        {language === 'es' ? 'Español' : 'English'}
      </button>
      </div>
      <div className="flex items-center py-4 justify-start gap-2">
        <a href="/treatment">
          <img src={logo} alt="logo" className="w-12 h-12" />
        </a>
        <h1 className="text-green-500 text-4xl font-poppins font-light">Reforma Dental</h1>
      </div>

      <div className="grid border-t grid-cols-1 gap-4 md:grid-cols-2 border-green-300">
        <div className="flex justify-center py-28">
          <Card className="mt-6 w-96 shadow-2xl shadow-green-200">
            <CardBody>
              <img className="border border-gray-500 rounded-2xl shadow-xl" src={product.image} alt={product.service} />
              <div className="flex justify-around font-poppins py-4 font-medium">
                <h1>
                {language === 'es'
                    ? product.service.es
                    : product.service.en}
                </h1>
                <p>{product.price}</p>
              </div>
              <div className="flex justify-center items-center py-4">
                <a href="/treatment">
                  <button className="text-white bg-green-500 hover:bg-green-700 rounded-xl px-8 py-2">
                    <Typography color="white" className="font-poppins font-bold">
                      {language === 'es'
                        ? 'Regresar'
                        : 'Return'}
                    </Typography>
                  </button>
                </a>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="text-justify  flex justify-center items-center font-poppins font-light">
          <span>
            {language === 'es'
              ? product.text.es
              : product.text.en
            }
          </span>
        </div>
      </div>
    </main>
  );
}

export default TreatmentDinamic;
