import {
  corona,
  dentadura,
  endodoncia,
  extracion,
  implantes,
  tratamiento,
  recina,
  ortodoncia,
  perio,
} from "./Images";

export const products = [
  {
    id: 1,
    image: corona,
    service: { es: "Corona", en: "Crown" },
    price: "$300",
    link: "/treatment/corona",
    text: {
      en: "Crowns are a reconstruction that works as a cover for the tooth with the natural symmetry and color of the tooth, this procedure is performed for the patient to have a good dental aesthetics. The dental crown is a coating that is applied to a damaged tooth, although this resource can be used to improve the appearance, shape or aesthetics of a tooth. They are made of porcelain or ceramic, but you can also use metal, gold or resin pieces.  It’s usually used to replace some part of the tooth that has been lost, as the crown is responsible for protecting the damaged tooth if it has serious fractures, sometimes it is used after root canal treatment. ",
      es: "Las coronas son una reconstrucción que funciona como una cubierta para el diente con la simetría y el color natural del diente, este procedimiento se realiza para que el paciente tenga una buena estética dental. La corona dental es un recubrimiento que se aplica a un diente dañado, aunque este recurso se puede utilizar para mejorar la apariencia, la forma o la estética de un diente. Están hechos de porcelana o cerámica, pero también se pueden usar piezas de metal, oro o resina.  Por lo general, se usa para reemplazar alguna parte del diente que se ha perdido, ya que la corona es la encargada de proteger el diente dañado si tiene fracturas graves, a veces se usa después del tratamiento de conducto. ",
    }
    },
  {
    id: 2,
    image: dentadura,
    service: { es: "Dentadura", en: "Denture" },
    price: "$700",
    link: "/treatment/dentadura",
    text: {
      en: "Removable dental devices known as dentures are used to replace lost teeth in the upper, lower, or both jaws. Dentures are crafted by dental technicians using materials like porcelain, acrylic, resin, nylon, and metal.  Dentures come in a variety of forms, such as implant-supported, partial, and complete dentures. A denture may last anywhere from seven to ten years with the right upkeep.",
      es: "Una prótesis dental removible conocida como dentadura se utiliza para reemplazar los dientes perdidos en la mandíbula superior, inferior o ambas. Las dentaduras son elaboradas por técnicos dentales utilizando materiales como porcelana, acrílico, resina, nylon y metal. Las dentaduras vienen en una variedad de formas, como dentaduras soportadas por implantes, parciales y completas. Una dentadura puede durar de siete a diez años con el cuidado adecuado."
    }
  },
  {
    id: 3,
    image: endodoncia,
    service: { es: "Endodoncia", en: "Endodontics" },
    price: "$300",
    link: "/treatment/endodoncia",
    text: {
      en: "Endodontics is commonly called killing the nerve. The procedure consists of removing the deep part of the tooth when it is injured or infected. The goal of this treatment is to clean the inside of the tooth and make a filling. This treatment can be used in case of pain or infection and is the last chance to save the tooth, because if it does not work, the only thing left to do is to remove the tooth completely. Root canal treatment takes 1 to 2 visits to the dental office. It is a small and painless procedure, because the tooth uses internal anesthesia to prevent irritation. Once finished, the patient no longer thinks about the pain they felt before the procedure.",
      es: "La endodoncia se conoce comúnmente como matar el nervio. El procedimiento consiste en eliminar la parte profunda del diente cuando está lesionado o infectado. El objetivo de este tratamiento es limpiar el interior del diente y hacer un relleno. Este tratamiento se puede utilizar en caso de dolor o infección y es la última oportunidad para salvar el diente, porque si no funciona, lo único que queda por hacer es extraer el diente por completo. El tratamiento de conducto tarda de 1 a 2 visitas a la consulta dental. Es un procedimiento pequeño e indoloro, porque el diente utiliza anestesia interna para evitar irritaciones. Una vez terminado, el paciente ya no piensa en el dolor que sintió antes del procedimiento.",
    }
  },
  {
    id: 4,
    image: extracion,
    service: { es: "Extracion", en: "Extraction" },
    price: "$150",
    link: "/treatment/extracion",
    text: {
      en: "A tooth extraction involves taking out your tooth entirely from its socket. This is sometimes referred to as pulling a tooth. This relatively quick outpatient procedure offers different anesthesia options, including local, general, intravenous, or a combination, ensuring patient comfort. Simple extractions are suitable for visible teeth, while more complex procedures are reserved for broken, below-surface, or impacted teeth. One frequent cause is a tooth that is too severely decayed or damaged from trauma for restoration. If you have crowding, infection, or tooth decay, extraction is advised. Even though permanent teeth are intended to last a lifetime, there are some situations in which tooth extraction may be necessary. Individuals undergoing orthodontic treatment, such as getting braces, may require one or two teeth to be removed to create space for proper alignment. ",
      es: "Una extracción dental implica sacar el diente completamente de su zócalo. Esto a veces se conoce como extraer un diente. Este procedimiento ambulatorio relativamente rápido ofrece diferentes opciones de anestesia, incluidas las locales, generales, intravenosas o una combinación de ellas, lo que garantiza la comodidad del paciente. Las extracciones simples son adecuadas para los dientes visibles, mientras que los procedimientos más complejos están reservados para los dientes rotos, por debajo de la superficie o impactados. Una causa frecuente es un diente que está demasiado deteriorado o dañado por un traumatismo para su restauración. Si tiene apiñamiento, infección o caries dental, se recomienda la extracción. Aunque los dientes permanentes están destinados a durar toda la vida, hay algunas situaciones en las que puede ser necesario extraer un diente. Las personas que se someten a un tratamiento de ortodoncia, como usar frenillos, pueden requerir que se extraigan uno o dos dientes para crear espacio para una alineación adecuada. ",
    }
  },
  {
    id: 5,
    image: implantes,
    service: { es: "Implantes", en: "Implants" },
    price: "$1650",
    link: "/treatment/implantes",
    text: {
      en: "Dental implants commonly replace rotten teeth because the tooth is usually no longer functional, with this procedure an artificial tooth is placed with the characteristics of the tooth that has been replaced so that the patient can enjoy an aesthetic smile. A dental implant is a popular and effective way to replace missing teeth and is designed to fit over other teeth. A great long-term opportunity to get your smile back. In fact, the development and use of implants is one of the greatest advances in dentistry in the last 40 years. Dental implants are made of titanium and other materials suitable for the human body. These screws are attached to the upper or lower jaw and serve as a secure retainer for the teeth.  ",
      es: "Los implantes dentales reemplazan comúnmente los dientes podridos porque el diente generalmente ya no es funcional, con este procedimiento se coloca un diente artificial con las características del diente que se ha reemplazado para que el paciente pueda disfrutar de una sonrisa estética. Un implante dental es una forma popular y efectiva de reemplazar los dientes perdidos y está diseñado para adaptarse a otros dientes. Una gran oportunidad a largo plazo para recuperar tu sonrisa. De hecho, el desarrollo y uso de implantes es uno de los mayores avances en odontología en los últimos 40 años. Los implantes dentales están hechos de titanio y otros materiales adecuados para el cuerpo humano. Estos tornillos se sujetan a la mandíbula superior o inferior y sirven como retenedor seguro para los dientes. ",
    },
  },
  {
    id: 6,
    image: tratamiento,
    service: { es: "Tratamiento", en: "Treatment" },
    price: "$50",
    link: "/treatment/tratamiento",
    text: {
      en: "Regular dental checkups are essential for maintaining your smile's cleanliness and look as well as your long-term oral health. Routine dental cleanings, direct dental restorations (dental fillings), and tooth replacements are three common types of general dentistry operations. These three are the most common dental operations that patients may anticipate from a routine appointment, however there are many more. A wide range of treatments and treatment options essential to preserving and promoting excellent oral health are covered by general dentistry. There are procedures in place to maintain the health and painlessness of your teeth, gums, and mouth.",
      es: "Los chequeos dentales regulares son esenciales para mantener la limpieza y el aspecto de su sonrisa, así como su salud bucal a largo plazo. Las limpiezas dentales de rutina, las restauraciones dentales directas (empastes dentales) y las sustituciones de dientes son tres tipos comunes de operaciones de odontología general. Estos tres son los procedimientos dentales más comunes que los pacientes pueden esperar de una cita de rutina, pero hay muchos más. La odontología general cubre una amplia gama de tratamientos y opciones de tratamiento esenciales para mantener y promover una excelente salud bucal. Existen procedimientos para mantener la salud y la ausencia de dolor de sus dientes, encías y boca.",
    },
  },
  {
    id: 7,
    image: recina,
    service: { es: "Recina", en: "Resin" },
    price: "$50",
    link: "/treatment/recina",
    text: {
      en: "Dental fillings are materials used to repair or restore teeth. They can be single or a mixture of metals, polymers, glass, or other materials. Fillings are frequently used to restore cavities or other areas of teeth that your dentist has removed due to decay.  A filling is used to fix a tiny hole, or cavity, in a tooth. A dentist will first extract the decaying tooth tissue from a cavity and then use a filling material to replace the empty area. To cure a cavity, your dentist will first extract the damaged tooth material and then fill the empty space on the tooth. In addition to fixing damaged or fractured teeth, fillings can also restore teeth that have worn down due to overuse (such chewing one's nails or grinding one's teeth).",
      es: "Los empastes dentales son materiales utilizados para reparar o restaurar los dientes. Pueden ser únicos o una mezcla de metales, polímeros, vidrio u otros materiales. Los empastes se utilizan con frecuencia para restaurar las caries u otras áreas de los dientes que su dentista ha extraído debido a la caries.  Un empaste se utiliza para arreglar un pequeño agujero, o caries, en un diente. Un dentista primero extraerá el tejido dental en descomposición de una caries y luego usará un material de relleno para reemplazar el área vacía. Para curar una caries, su dentista primero extraerá el material dental dañado y luego llenará el espacio vacío en el diente. Además de reparar dientes dañados o fracturados, los empastes también pueden restaurar dientes que se han desgastado debido al uso excesivo (como morderse las uñas o rechinar los dientes).",
    }
  },
  {
    id: 8,
    image: ortodoncia,
    service: { es: "Ortodoncia", en: "Orthodontics" },
    price: "$650",
    link: "/treatment/ortodoncia",
    text: {
      en: "Orthodontics is a specialized dental practice that examines, prevents and corrects developmental changes. These can be, the nature of the dental arches, the position of the jaw to restore balance, the morphological function of the mouth, the joints, and to enhance the aesthetics of the smile. This treatment is usually for misaligned teeth, the solution for this is orthodontics in which the teeth are accommodated in the necessary position, to achieve their original function and as a result obtain perfectly aligned teeth. There are several types of orthodontic treatments, such as metal brackets, aesthetic brackets, braceless brackets and even completely transparent systems. There are different types of material such as metal, resin or sapphire and styles depending on the dentist's recommendation.",
      es: "La ortodoncia es una práctica dental especializada que examina, previene y corrige los cambios de desarrollo. Estos pueden ser, la naturaleza de los arcos dentales, la posición de la mandíbula para restaurar el equilibrio, la función morfológica de la boca, las articulaciones y mejorar la estética de la sonrisa. Este tratamiento suele ser para los dientes mal alineados, la solución para esto es la ortodoncia en la que los dientes se acomodan en la posición necesaria, para lograr su función original y como resultado obtener dientes perfectamente alineados. Existen varios tipos de tratamientos de ortodoncia, como los brackets metálicos, los brackets estéticos, los brackets sin brackets e incluso sistemas completamente transparentes. Existen diferentes tipos de material como metal, resina o zafiro y estilos dependiendo de la recomendación del dentista.",
    }
  },
  {
    id: 9,
    image: perio,
    service: { es: "Periodoncia", en: "Periodontics" },
    price: "$400",
    link: "/treatment/perio",
    text: {
      en: "If you have had problems with your mouth, such as a sore throat or swelling, it may be a periodontal disease related to oral health. This type of disease can affect our daily routine because when we chew our food or brush our teeth, the pain in our mouth increases and prevents us from enjoying our food. Periodontics deals with diseases of the gums and the bones that support the teeth. And if cervical diseases such as gingivitis and periodontitis are the most common causes of tooth loss, treatment is important. Periodontics focuses on the prevention, diagnosis and treatment of diseases or disorders that affect the muscles that support the teeth. Periodontal diseases include gingivitis and periodontitis. These can be combined with other diseases such as bleeding, bad breath or even tooth decay. For the treatment of periodontal disease in these types of pathologies, a deep cleaning to remove plaque and tartar is common. This procedure is used to reduce inflammation and remove bacterial deposits that have accumulated in the gums and roots of the teeth. ",
      es: "Si ha tenido problemas con la boca, como dolor de garganta o hinchazón, puede ser una enfermedad periodontal relacionada con la salud bucal. Este tipo de enfermedad puede afectar nuestra rutina diaria porque cuando masticamos nuestra comida o nos cepillamos los dientes, el dolor en la boca aumenta y nos impide disfrutar de nuestra comida. La periodoncia se ocupa de las enfermedades de las encías y los huesos que sostienen los dientes. Y si las enfermedades cervicales como la gingivitis y la periodontitis son las causas más comunes de pérdida de dientes, el tratamiento es importante. La periodoncia se centra en la prevención, el diagnóstico y el tratamiento de enfermedades o trastornos que afectan a los músculos que sostienen los dientes. Las enfermedades periodontales incluyen la gingivitis y la periodontitis. Estos se pueden combinar con otras enfermedades como sangrado, mal aliento o incluso caries. Para el tratamiento de la enfermedad periodontal en este tipo de patologías, es común una limpieza profunda para eliminar la placa y el sarro. Este procedimiento se utiliza para reducir la inflamación y eliminar los depósitos bacterianos que se han acumulado en las encías y las raíces de los dientes.",
    }
  },
];
