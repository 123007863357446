import React from 'react'
import BlogPage from './Pages/BlogPage'
function Blog() {
  return (
    <div>
        <BlogPage /> 
    </div>
  )
}

export default Blog