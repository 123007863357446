// Blog.js
import React from "react";
import { useParams } from "react-router-dom";
import { articles } from "../Components/data";
import { articleSelecteds } from "../Components/dataSection";
import { Button } from "@material-tailwind/react";

const Blog = () => {
  const { id } = useParams();
  const article = articles.find((article) => article.href === `/blog/${id}`);
  const articleSelected = articleSelecteds.find(
    (articleSelected) => articleSelected.href === `/blog/${id}`
  );
  console.log(articleSelected);
  if (articleSelected) {
    return (
      <main className="h-full bg-blue-gray-800">
        <div className="text-black mx-96">
          <h1 className="py-8 text-4xl text-center text-white">
            {article.title}
          </h1>
          <div className="grid grid-cols-2">
          <div>
              <img
                className="object-cover w-3/4 shadow-2xl rounded-2xl h-72"
                src={article.image}
                alt="natureImage"
              />
            </div>
            <div className="m-auto">
              <p className="text-2xl text-white">{article.description}</p>
              <p className="py-4 text-2xl text-gray-400 text-start">
                Date: {article.date}
              </p>
            </div>
          </div>
        </div>
        <div className="my-4 text-white border-t mx-72"/>
        <div className="py-4 mx-64 text-center text-white">
          {articleSelected && (
            <div className="[&>p]:py-4 [&>img]:shadow-2xl text-justify flex justify-center items-center flex-col">
              <p className="text-4xl">{articleSelected.content}</p>
              <p className="text-xl">{articleSelected.content2}</p>
              <img
                src={articleSelected.image1}
                className="object-cover w-3/5 rounded-2xl h-72"
                alt={articleSelected.image1}
              />
              <p className="text-4xl">{articleSelected.content3}</p>
              <p className="text-2xl">{articleSelected.content4}</p>
              <img
                src={articleSelected.image2}
                className="object-cover w-3/5 rounded-2xl h-72"
                alt={articleSelected.image2}
              />
              <p className="text-3xl">{articleSelected.content5}</p>
              <img
                src={articleSelected.image3}
                className="object-cover w-3/5 rounded-2xl h-72"
                alt={articleSelected.image3}
              />
            </div>
          )}
        </div>
        <footer>
            <div className="py-8 border-t to-white">
                <span>
                    <a target="_blank" rel="noreferrer" href="https://www.e-commetrics.com" className="flex items-center justify-center text-xl text-center text-white hover:text-green-300">
                    Todos los derechos reservados a Reforma Dental, @ 2023 desarrollado por Ecommetrica.com
                    </a>
                </span>
            </div>
        </footer>
      </main>
    );
  } else {
    return <div className="flex flex-col items-center justify-center h-screen text-white bg-blue-gray-800">
        <p className="py-12 text-5xl">Blog no encontrado</p>
        <a href="/blog">
            <Button variant="gradient" color="blue">Regresar blog</Button>
        </a>
    </div>;
  }
};

export default Blog;
