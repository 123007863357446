import React, {useState} from "react";
import logo from "../img/logo.webp";
import { FaPhone, FaLocationDot } from "react-icons/fa6";
import { TypeAnimation } from "react-type-animation";
import mexico from "../img/Mexico.png";
import usa from "../img/USA.png";
function ContactPage() {
  const [language, setLanguage] = useState('es'); // Estado para el idioma actual

  const toggleLanguage = () => { // Función para cambiar el idioma
    setLanguage(language === 'es' ? 'en' : 'es');
  };

const text = {
  es: `En nuestra oficina dental, entendemos que tu salud oral es una
  parte importante de tu bienestar general. Es por eso que tenemos un equipo
  de dentistas experimentados y especialistas en cuidado de la salud oral que están
  dedicados a proporcionar el más alto nivel de atención profesional para
  tu boca. Ya sea que necesites alivio del dolor dental en el nervio,
  quieras mejorar la estética de tu sonrisa, necesites restaurar
  la funcionalidad a tu boca, o estés considerando implantes dentales, tenemos
  la experiencia para ayudar. Nuestra instalación de última generación está
  equipada con la última tecnología y técnicas para asegurar que
  tu visita sea cómoda y eficiente. Ofrecemos una amplia gama de
  servicios para satisfacer todas tus necesidades dentales. Contáctanos hoy para
  programar una cita y dar el primer paso hacia una sonrisa más saludable,
  más segura.`,
  en: `At our dental office, we understand that your oral health is an
  important part of your overall well-being. That's why we have a team
  of experienced dentists and specialists in oral health care who are
  dedicated to providing the highest level of professional care for
  your mouth. Whether you need relief from dental pain in the nerve,
  want to improve the aesthetics of your smile, need to restore
  functionality to your mouth, or are considering dental implants, we
  have the expertise to help. Our state-of-the-art facility is
  equipped with the latest technology and techniques to ensure that
  your visit is comfortable and efficient. We offer a wide range of
  services to meet all of your dental needs. Contact us today to
  schedule an appointment and take the first step toward a healthier,
  more confident smile.`
};
  return (
    <main className="m-12 sm:mx-48  md:mx-52 md:my-24">
      <div className="flex justify-start items-center gap-4">
      <img src={language === 'es' ? mexico : usa} alt="language" className="w-16" />
      <button onClick={toggleLanguage} className=" bg-green-500 hover:bg-green-700 border rounded-lg px-4 text-white">
        {language === 'es' ? 'Español' : 'English'}
      </button>
      </div>
      <div className="flex items-center py-4 justify-start gap-2">
        <a href="/">
            <img src={logo} alt="logo" className="w-12 h-12" />
        </a>
        <h1 className="text-green-500 text-4xl">
      <TypeAnimation
        cursor={true}
        sequence={['Reforma Dental', 1000]}
        wrapper="span"
        speed={10}
        />
    </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 py-8 gap-8 border-t border-green-200">
        <div className="font-poppins font-light animate-fade-right animate-duration-[2000ms] animate-normal">
          <h1 className="font-poppins text-3xl text-green-500 font-medium">
            {language === 'es' ? 'Informacion de contacto' : 'Contact information'}
          </h1>
          <span className="flex justify-start items-start text-justify">
            {text[language]}
          </span>
          <a
            href="https://api.whatsapp.com/send?phone=+16195861436&text=Hola,%20visite%20su%20pagina%20y%20quisiera%20conocer%20más%20sobre%20sus%20servicios%20dentales"
            target="_blank"
            rel="noreferrer"
          >
            <button className="border rounded-xl px-4 py-2 bg-green-400 font-medium hover:bg-green-600 text-white">
               {language === 'es' ? 'Contáctanos' : 'Contact us'}
            </button>
          </a>
        </div>
        <div className="font-poppins animate-fade-left animate-duration-[2000ms] animate-normal">
          <h1 className="font-poppins text-3xl text-green-500 font-medium">
            {language === 'es' ? 'Ubicación' : 'Location'}
          </h1>
          <div className="flex flex-col">
            <div>
              <FaLocationDot className="inline-block mr-2 text-green-500" />
              <span>
                8169-206 Ignacio Zaragoza, Zona Centro, Tijuana, Baja
                California, México.
              </span>
            </div>
            <div>
              <FaPhone className="inline-block mr-2 text-green-500" />
              <span>+1 619-586-1436</span>
            </div>
          </div>
          <div
            className="relative h-0 mt-4"
            style={{ paddingBottom: "56.25%" }}
          >
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.008303115534!2d-117.03663105457193!3d32.52592519987922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d9499b13b355e9%3A0x33f0692b196212e5!2sReforma%20Dental!5e0!3m2!1ses!2smx!4v1696869140483!5m2!1ses!2smx"
              title="Ubicación de tu empresa en Google Maps"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <footer className="border-t border-green-200 py-16 font-poppins font-medium">
        <div className="container mx-auto text-center">
          <p className="text-green-500 text-2xl">
            {language === 'es' ? '@ 2022 Reforma Dental. Todos los derechos reservados.' : '© 2022 Reforma Dental. All rights reserved.'}
          </p>
        </div>
      </footer>
    </main>
  );
}

export default ContactPage;
