// HomeSection.js
import React from 'react';
import { useLanguage } from '../Components/languageContext'  // Asegúrate de importar el hook
import { translations } from '../Components/translations'  // Asegúrate de importar el hook

function HomeSection() {
    const { language } = useLanguage();  // Obtiene el idioma actual

    // Accede a las traducciones basadas en el idioma actual
    const { title, subtitle, buttonText, animation } = translations[language].home;

    return (
        <section id="Home" className="bg-gray-100">
            <div className="container mx-auto text-center">
                <img src={animation} alt="Video Hero" type="video/mp4" className="w-2/3 h-auto mx-auto" />
                <h1 className="mb-4 text-4xl font-bold text-gray-500">{title}</h1>
                <h2 className="mb-5 text-3xl text-gray-500">{subtitle}</h2>
                <a href='#Booking'>
                    <button className="bg-[#87D717] hover:bg-[#95F50E] text-1xl mb-12 text-white font-bold py-3 px-20 mt-5 rounded-full">
                        {buttonText}
                    </button>
                </a>
            </div>
        </section>
    )
}

export default HomeSection;
