import React from 'react'
import logo from '../img/logo.webp';
import {
    Typography,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button
} from "@material-tailwind/react";
import { useLanguage } from '../Components/languageContext';
import { translations } from '../Components/translations';

function FooterSection() {

    const { language } = useLanguage();
    const [openFooterTermsOfService, setFooterOpen] = React.useState(false);
    const handleFooterTermsOfService = () => setFooterOpen(!openFooterTermsOfService);

    const [openFooterPrivaPolicy, setFooterPrivacyOpen] = React.useState(false);
    const handleFooterPrivacyPolicyOpen = () => setFooterPrivacyOpen(!openFooterPrivaPolicy);

    const [openFooterAbout, setFooterAboutOpen] = React.useState(false);
    const handleFooterAbout = () => setFooterAboutOpen(!openFooterAbout);
    return (
        <div><footer className="w-full p-4 bg-white md:p-8">
            <div className="flex flex-col items-center justify-center gap-6 text-center bg-white md:flex-row md:justify-between">
                <img src={logo} alt="logo-ct" className="w-10" />
                <ul className="flex flex-wrap items-center gap-2 md:gap-8">
                    <li>
                        <Typography
                            as="a"
                            color="blue-gray"
                            onClick={handleFooterAbout}
                            className="font-normal transition-colors hover:text-green-300 focus:text-green-300"
                        >
                        {translations[language].footer.aboutUs}
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            color="blue-gray"
                            onClick={handleFooterTermsOfService}
                            className="font-normal transition-colors hover:text-green-300 focus:text-green-300"
                        >
                        {translations[language].footer.termsOfService}
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            onClick={handleFooterPrivacyPolicyOpen}
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-green-300 focus:text-green-300"
                        >
                        {translations[language].footer.privacyPolicy}
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            href='/contact'
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-green-300 focus:text-green-300"
                        >
                        {translations[language].footer.contactUs}
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            href="#Booking"
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-green-300 focus:text-green-300"
                        >
                        {translations[language].footer.booking}
                        </Typography>
                    </li>
                </ul>
            </div>
            <hr className="my-4 md:my-8 border-blue-gray-50" />
            <Typography
                as="a"
                href="https://www.e-commetrics.com"
                target="_blank" // Agregar este atributo
                rel="noopener noreferrer" // Agregar por razones de seguridad
                color="blue-gray"
                className="font-normal text-center transition-colors hover:text-green-300 focus:text-green-300"
            >
            {translations[language].footer.copyright}
            </Typography>
        </footer>
            <Dialog open={openFooterTermsOfService} handler={handleFooterTermsOfService}>
                <DialogHeader className='flex justify-center'>Terms of Service</DialogHeader>
                <DialogBody divider className="h-[20rem] overflow-scroll">
                    <Typography className="font-normal">
                        Once you have selected the service you want, you must reserve the service during the times available on the platform.
                        <br /> <br />
                        The agent services reserve the space in the office at the times as selected at the time of your selection, our system only allows modifications to be made within the page.
                        <br /> <br />
                        Once the reservation is completed, you will receive an email from us, within a maximum of 2 hours with the reservation information.
                        <br /> <br />
                        If you make a deposit, once the money is reflected in our account, a confirmation call will be made up to 24 hours before the reservation confirming the order by email. Confirmation of the purchase deposit must be confirmed by the administrative area. In no case can the space be scheduled if it is not released by the corresponding department.            <br />
                        <br />
                        <h1 className='font-bold text'>Reservation Policy. </h1>
                        Any reservation made on the page goes directly to our agenda at the reception.
                        Appointments are confirmed by telephone and must be attended by the patient in the period of 48 hours to 24 hours before the appointment. Unconfirmed appointments may be considered canceled by the patient.
                        Cancellation with refund of the appointment may be made as long as the notification is 72 hours before the scheduled date. If it is within the 72 hour period, only the appointment can be rescheduled.
                        Any situation that arises due to exchange or return must be notified by email to mydentist@dentalreforma.com sending a description of the problem or reason for the cancellation.
                    </Typography>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleFooterTermsOfService}>
                        close
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={openFooterPrivaPolicy} handler={handleFooterPrivacyPolicyOpen}>
                <DialogHeader className='flex justify-center'>Privacy Policy</DialogHeader>
                <DialogBody divider className="h-[20rem] overflow-scroll">
                    <Typography className="font-normal">
                        At Reformadental.com, accessible from https://www.reformadental.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains the types of information that are collected and recorded by Reforma Dental and how we use it.
                        <br />
                        Communicate with you, either directly or through one of our partners, including customer service, to provide you with updates and other information related to the site, and for marketing and promotional purposes.
                        Send you emails
                        Find and prevent fraud
                        <br /><br />
                        Log files
                        Reforma Dental.com follows a standard procedure of using log files. These files record visitors when they visit the websites. All hosting companies do this and part of the analysis of hosting services. The information collected by log files includes Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the page number. of clicks. These are not linked to any personally identifiable information. The purpose of the information is to analyze trends, administer the site, track users' movement around the website, and gather demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and Disclaimer Generator.
                        <br /><br />
                        Cookies and Web Beacons
                        Like any other website, Reforma Dental.com uses "cookies". These cookies are used to store information, including visitors' preferences, and the pages of the website that the visitor accessed or visited. The information is used to optimize users' experience by customizing our website content based on visitors' browser type and/or other information.
                        <br /><br />
                        For more general information about cookies, read "What are cookies?
                        <br /><br />
                        Google DoubleClick DART Cookie
                        Google is one of the third party providers of our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based on their visit to www.website.com and other sites on the Internet. However, visitors may choose to decline the use of DART cookies by visiting the Google Ad and Content Network Privacy Policy at the following URL – https://policies.google.com/technologies/ads
                        <br /><br />
                        Our advertising partners
                        Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own privacy policy for their user data policies. For easy access, we have created a hyperlink to their privacy policies below.
                        <br /><br />
                        Google
                        https://policies.google.com/technologies/ads
                        <br /><br />
                        Privacy policies of advertising partners
                        You may consult this list to find the privacy policy for each of the advertising partners of Reforma Dental.com
                        <br /><br />
                        Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Reforma Dental.com, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content you see on the websites you visit.
                        <br /><br />
                        Please note that Reforma Dental has no access to or control over these cookies that are used by third-party advertisers.
                        <br /><br />
                        Third Party Privacy Policies
                        The Reforma Dental privacy policy presented applies to other advertisers or websites. Therefore, we advise you to consult the respective privacy policies of these third-party ad servers for more detailed information. It may include its practices and instructions about how to opt out of certain options.
                    </Typography>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleFooterPrivacyPolicyOpen}>
                        close
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={openFooterAbout} handler={handleFooterAbout}>
                <DialogHeader className='flex justify-center'>About Us</DialogHeader>
                <DialogBody divider className="h-[20rem] overflow-scroll">
                    <Typography className="font-normal">
                        Dr. Karen Perez and her husband, Juan González, had always dreamt of starting their own business in downtown Tijuana, Baja California. After years of hard work, extensive education, and numerous successful practices, the couple had finally gathered the necessary funds to make their dream a reality.
                        <br />
                        Dr. Perez had always been passionate about dentistry and had completed her undergraduate degree in Odontology from Baja California State University (UABC). After that, she attended the prestigious Health Science Faculty, at Valle de Las Palmas Campus (UABC). She then went on to complete a rigorous two-year program in ROOT CANAL at the School of Dentistry (CUPIS), where she earned her Doctor of Dental Surgery (DDS) degree in Endodontics.
                        <br />

                        Juan, on the other hand, had a background in business and finance. He had earned his undergraduate degree in International Business from the San Diego State University (SDSU) and had gone on to receive an MS from COLEF Economics School.
                        <br />

                        Together, the couple had the perfect combination of knowledge and skills to start a successful business. They had previously researched the market in Tijuana’s heterogeneous market and found that there was a high demand for top-quality dental care and personal attention services in low earning districts. Meanwhile the streets were full of dental ads for new offices opening every other day in locations close to downtown and the border. However, they had a vision of success over everyone doing the same services.
                        <br />

                        Accordingly, the couple decided to re-open a state-of-the-art dental practice, equipped with the latest technology, and staffed with highly qualified professionals. They started
                        <br />

                        to work in a renovation in one of the most crowded, low earning, popular locations in Tijuana and invested heavily in the renovation of the space. They hired a team of experienced architects and designers who created a modern and inviting environment, complete with the latest dental equipment and technology.
                        <br />

                        Dr. Perez was especially excited about the technology they were able to incorporate into the practice to make possible for the dental team to diagnose and treat oral health issues more precisely and efficiently than ever before.
                        <br />

                        The couple also invested in top-of-the-line dental chairs that provided maximum comfort for their patients. They had hired a team of highly qualified dental hygienists and assistants who were trained to provide top-quality care and make every patient feel at ease at a comfortable and harmonious space.
                        <br />

                        The practice was an instant success among family and friends. More patients were suddenly impressed by the high-quality care they received and the state-of-the-art facilities. Word of mouth spread quickly, and the practice soon became known as a top destination in Tijuana for anyone in need of top-quality dental care for root canal treatments at a fraction of the cost.
                        <br />

                        Their dedication to their patients and their community continued to inspire others, and their practices continued to thrive. Dr. Perez and Juan’s successful partnership had not only allowed them to build a thriving business, but it had also allowed them to make a difference in the lives of countless people.
                        <br />

                        After extensive research, the couple discovered that many people were traveling across the border to Tijuana, Mexico, to receive affordable dental care. They knew that they could make a difference by opening a new facility in Tijuana and providing the same high-quality care and safe practices that they were known for in their previous practice.
                        <br />

                        So, once again, they assembled a team of highly qualified dental professionals in downtown Tijuana and began the process of opening a new facility. They made sure to invest in the same state-of-the-art equipment and technology that they had in their previous practice, and they trained their staff on the same safe practices and quality standards.
                        <br />

                        The new facility was an instant success. Patients were thrilled to receive the same high-quality care that they had come to expect from Dr. Perez and Juan's practice, but at a
                        <br />

                        fraction of the cost. The Tijuana facility quickly became known as a go-to destination for anyone looking for affordable dental care without sacrificing quality or safety.
                        <br />

                        Dr. Perez and Juan were thrilled with the success of their new venture, closer to the border, but they knew that there was still more work to be done. They were determined to continue to provide the highest level of care to all their patients, regardless of their ability to pay.
                        <br />

                        To that end, they established a scholarship program to help fund the education of aspiring dental professionals in the United States and Mexico. They also worked with local organizations to provide free dental care to those in need, both in San Diego and Tijuana.
                        <br />

                        They were grateful for the opportunity to do what they loved every day, and they looked forward to many more years of providing top-quality dental care to their patients.
                        <br />

                        But what really set Perez’s practice apart was the successful marriage behind it all. Dr. Perez and Juan had been married for over 6 years and their successful partnership extended beyond their personal relationship. They had a natural rhythm, they balanced each other out, and they both understood the importance of hard work and dedication to achieve their goals.
                        <br />

                        Their staff noticed the way they communicated with each other, their mutual respect, and the way they worked together to make important decisions for the practice. It was evident that their successful partnership was the foundation of the practice's success.
                        <br />

                        Dr. Perez and John were thrilled with their success and were grateful for the opportunity to serve the people of Tijuana. They knew that their success was due to their hard work, dedication, and passion for dentistry, and they were determined to continue to provide the highest level of care to their patients for many years to come.
                        <br />

                        As Dr. Perez and Juan’s dental practice in Greater Tijuana and San Diego continued to thrive, they began to think about ways to expand their reach and provide affordable dental care to even more patients. They knew that many people in the United States struggled to afford high-quality dental care, and they wanted to find a way to make their services more accessible.
                    </Typography>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleFooterAbout}>
                        close
                    </Button>
                </DialogFooter>
            </Dialog></div>
    )
}

export default FooterSection