import React from "react";
import {
  endodoncia,
  ortodoncia,
  implantes,
  extracion,
  corona,
  recina,
  perio,
  dentadura,
  tratamiento,
} from "../Components/Images";
import { useLanguage } from "../Components/languageContext";
import { translations } from "../Components/translations";

function TreatmetSection() {
  const { language } = useLanguage();

  return (
    <section id="Treatmets" className="bg-[#F5FFE5] py-8 lg:py-24">
      <div className="container mx-auto">
        <h1 className="px-4 py-4 mb-2 text-4xl font-poppins font-bold text-center text-black lg:py-8">
          {translations[language].treatments.title}
        </h1>
        <div className="grid grid-cols-1 gap-4 py-4 text-xl font-poppins font-bold lg:grid-cols-3 lg:py-8 lg:text-2xl">
          <div className="flex flex-col">
            <img
              alt={endodoncia}
              src={endodoncia}
              className="h-auto mx-auto w-52"
            />
            <p className="text-center">
              {translations[language].treatments.rootCanal}
            </p>
            <p className="text-[#9DC216] text-center">$300.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img
              alt={ortodoncia}
              src={ortodoncia}
              className="h-auto mx-auto w-52"
            />
            <p className="text-center">
              {translations[language].treatments.braces}
            </p>
            <p className="text-[#9DC216] text-center">$650.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img
              alt={implantes}
              src={implantes}
              className="h-auto mx-auto w-52"
            />
            <p className="text-center">
              {translations[language].treatments.implants}
            </p>
            <p className="text-[#9DC216] text-center">$1650.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img
              alt={extracion}
              src={extracion}
              className="h-auto mx-auto w-52"
            />
            <p className="text-center">
              {translations[language].treatments.extraction}
            </p>
            <p className="text-[#9DC216] text-center">$150.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img alt={corona} src={corona} className="h-auto mx-auto w-52" />
            <p className="text-center">
              {translations[language].treatments.crowns}
            </p>
            <p className="text-[#9DC216] text-center">$300.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img alt={recina} src={recina} className="h-auto mx-auto w-52" />
            <p className="text-center">
              {translations[language].treatments.fillings}
            </p>
            <p className="text-[#9DC216] text-center">$50.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img alt={perio} src={perio} className="h-auto mx-auto w-52" />
            <p className="text-center">
              {translations[language].treatments.periodontics}
            </p>
            <p className="text-[#9DC216] text-center">$400.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img
              alt={dentadura}
              src={dentadura}
              className="h-auto mx-auto w-52"
            />
            <p className="text-center">
              {translations[language].treatments.dentures}
            </p>
            <p className="text-[#9DC216] text-center">$700.00</p>
          </div>
          <div className="flex flex-col mb-7">
            <img
              alt={tratamiento}
              src={tratamiento}
              className="h-auto mx-auto w-52"
            />
            <p className="text-center">
              {translations[language].treatments.generalTreatments}
            </p>
            <p className="text-[#9DC216] text-center">$50.00</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TreatmetSection;
