import animacionEN from "../img/animacion.gif";
import animacionES from "../img/animacion2.gif";

export const translations = {
  en: {
    home: {
      title: "Family dental office",
      subtitle: "Welcome, we've open calendar at downtown Tijuana",
      buttonText: "BOOK US NOW",
      animation: animacionEN,
    },
    navbar: {
      home: "Home",
      mission: "Mission",
      solutions: "Solutions",
      treatments: "Treatments",
      booking: "Booking",
      contact: "Contact",
      sendMessage: "Send Message",
    },
    mission: {
      title: "Dental pain? We can help",
      subtitle1:
        "IT'S NEVER TOO LATE TO START TAKING CARE OF YOUR ORAL HEALTH!",
      subtitle2:
        "At Reforma Dental we do expert care for every dental need. We make dental care easy and stress-free.",
      professionalism: {
        title: "PROFESSIONALISM",
        content:
          "Experience our difference with our team of dental professionals. We go above and beyond for your smile by using the latest technology for a better dental experience.",
      },
      smile: {
        title: "SMILE & AESTHETICS",
        content:
          "Trust us to restore function and beauty to your mouth. Contact us for expert care in every dental need to transform your smile so transform your life.",
      },
      technology: {
        title: "TECHNOLOGY",
        content:
          "Feel comfortable and confident at our dental office. Get the smile you deserve with our wide range of services at our state-of-the-art facility.",
      },
      experience: {
        title: "EXPERIENCE",
        content:
          "Your oral health is our top priority. From routine cleanings to complex procedures, we've got you covered. Your oral health is in good hands with us.",
      },
    },
    solutions: {
      description1:
        "At our dental office, we understand that your oral health is an important part of your overall well-being. Whether you need relief from dental pain in the nerve, want to improve the aesthetics of your smile, need to restore functionality to your mouth, or are considering dental implants, we have the expertise to help and the professionalism to care for your family as our friends.",
      description2:
        "Contact us today and schedule an appointment to take the first step toward a healthier and more confident smile.",
      title: "Experience the difference at our dental office in TIJUANA",
      affirmation1:
        "Do we make dental care easy and stress-free? Of course, we do!",
      affirmation2: "We accept almost all dental insurance companies.",
    },
    treatments: {
      title: "From simple check-ups to complex procedures, we do it all!",
      rootCanal: "ROOT CANAL / ENDODONTICS",
      braces: "BRACES / ORTHODONTICS",
      implants: "IMPLANTS",
      extraction: "EXTRACTION",
      crowns: "CROWNS",
      fillings: "FILLINGS",
      periodontics: "PERIODONTICS",
      dentures: "DENTURES/PROSTHODONTICS",
      generalTreatments: "GENERAL TREATMENTS",
    },
    booking: {
      sectionTitle: "BOOK US NOW",
      subTitle: "Select a date and time to save your dental care day",
      address:
        "8169-206 Ignacio Zaragoza, Zona Centro, Tijuana, Baja California, México.",
      pickAppointment: "Pick your appointment here",
      timeZone: "Event time zone: America/Tijuana GMT-07:00",
      placeholderName: "Your Name",
      placeholderEmail: "Email for booking confirmation",
      placeholderPhone: "Phone for booking confirmation",
      buttonBookNow: "BOOK US NOW",
      modalTitle: "Reservation confirmed",
      modalBody1: "Thank you for scheduling your appointment!",
      modalBody2:
        "Your appointment has been generated and an email has been sent to your email address.",
      modalEvent: "Event: Dental appointment with Reforma Dental",
      modalLocation:
        "Location: 8169-206 Ignacio Zaragoza, Zona Centro, Tijuana",
      modalTime: "Time",
      createAppointment: "CREATE DENTAL APPOINTMENT",
      chooseDate: "Choose date",
      time: "Date:",
      confirm: "Done",
      email: "Email:",
      card: "Card number:",
      data: "en-US",
    },
    contact: {
      title: "Where to find us?",
      subtitle: "At any doubt don't hesitate to reach for us",
      buttonLabel: "Show location",
    },
    footer: {
      aboutUs: "About Us",
      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      contactUs: "Contact Us",
      booking: "Booking",
      copyright:
        "All rights reserved to Reforma Dental, @2023 developed by Ecommetrica.com",
    },
    stripe: {
      payment: "Confirm Reservation",
      name: "Name",
      email: "Email",
      phone: "Phone",
      appointment: "Appointment",
      price: "Price",
      reserve: "Reserve",
      cancel: "Cancel",
      download: "Download",
      loading: "Loading...",
    },
    calendar: {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      calendarDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      Days: ["S", "M", "T", "W", "T", "F", "S"],
      DayNotAvailable: "No time available",
      DayAvailable: "Select a time",
    },
  },

  es: {
    home: {
      title: "Consultorio dental familiar",
      subtitle: "Bienvenidos, hemos abierto calendario en el centro de Tijuana",
      buttonText: "RESÉRVANOS AHORA",
      animation: animacionES,
    },
    navbar: {
      home: "Inicio",
      mission: "Misión",
      solutions: "Soluciones",
      treatments: "Tratamientos",
      booking: "Reservar",
      contact: "Contacto",
      sendMessage: "Enviar Mensaje",
    },
    mission: {
      title: "¿Dolor dental? Podemos ayudarte",
      subtitle1:
        "¡NUNCA ES DEMASIADO TARDE PARA COMENZAR A CUIDAR TU SALUD ORAL!",
      subtitle2:
        "En Reforma Dental brindamos atención experta para todas las necesidades dentales. Hacemos que el cuidado dental sea fácil y sin estrés.",
      professionalism: {
        title: "PROFESIONALISMO",
        content:
          "Experimenta la diferencia con nuestro equipo de profesionales dentales. Vamos más allá por tu sonrisa utilizando la última tecnología para una mejor experiencia dental.",
      },
      smile: {
        title: "SONRISA Y ESTÉTICA",
        content:
          "Confía en nosotros para restaurar la función y belleza de tu boca. Contáctanos para una atención experta en todas las necesidades dentales y transforma tu sonrisa, transforma tu vida.",
      },
      technology: {
        title: "TECNOLOGÍA",
        content:
          "Siéntete cómodo y seguro en nuestra clínica dental. Obtén la sonrisa que mereces con nuestra amplia gama de servicios en nuestras instalaciones de vanguardia.",
      },
      experience: {
        title: "EXPERIENCIA",
        content:
          "Tu salud oral es nuestra máxima prioridad. Desde limpiezas rutinarias hasta procedimientos complejos, te tenemos cubierto. Tu salud oral está en buenas manos con nosotros.",
      },
    },
    solutions: {
      description1:
        "En nuestro consultorio dental, entendemos que su salud bucal es una parte importante de su bienestar general. Ya sea que necesite alivio del dolor dental en el nervio, quiera mejorar la estética de su sonrisa, necesite restaurar la funcionalidad de su boca o esté considerando implantes dentales, tenemos la experiencia para ayudar y el profesionalismo para cuidar a su familia como nuestros amigos.",
      description2:
        "Contáctenos hoy y programe una cita para dar el primer paso hacia una sonrisa más saludable y confiada.",
      title:
        "Experimente la diferencia en nuestro consultorio dental en TIJUANA",
      affirmation1:
        "¿Hacemos que el cuidado dental sea fácil y sin estrés? ¡Por supuesto que sí!",
      affirmation2: "Aceptamos casi todas las compañías de seguros dentales.",
    },
    treatments: {
      title:
        "Desde chequeos simples hasta procedimientos complejos, ¡lo hacemos todo!",
      rootCanal: "TRATAMIENTO DE CANAL / ENDODONCIA",
      braces: "BRACKETS / ORTODONCIA",
      implants: "IMPLANTES",
      extraction: "EXTRACCIÓN",
      crowns: "CORONAS",
      fillings: "RELLENOS",
      periodontics: "PERIODONCIA",
      dentures: "DENTADURAS/PROSTODONCIA",
      generalTreatments: "TRATAMIENTOS GENERALES",
    },
    booking: {
      sectionTitle: "RESÉRVANOS AHORA",
      subTitle:
        "Selecciona una fecha y hora para guardar tu día de atención dental",
      address:
        "8169-206 Ignacio Zaragoza, Zona Centro, Tijuana, Baja California, México.",
      pickAppointment: "Elige tu cita aquí",
      timeZone: "Zona horaria del evento: America/Tijuana GMT-07:00",
      placeholderName: "Tu nombre",
      placeholderEmail: "Correo electrónico para confirmación de reserva",
      placeholderPhone: "Teléfono para confirmación de reserva",
      buttonBookNow: "RESÉRVANOS AHORA",
      modalTitle: "Reserva confirmada",
      modalBody1: "¡Gracias por programar su cita!",
      modalBody2:
        "Su cita ha sido generada y se ha enviado un correo a su dirección de correo electrónico.",
      modalEvent: "Evento: Cita dental con Reforma Dental",
      modalLocation:
        "Ubicación: 8169-206 Ignacio Zaragoza, Zona Centro, Tijuana",
      modalTime: "Hora",
      createAppointment: "CREAR CITA DENTAL",
      chooseDate: "Elegir fecha",
      time: "Fecha:",
      confirm: "Hecho",
      email: "Correo electrónico:",
      card: "Numero de tarjeta:",
      data: "es-ES",
    },
    contact: {
      title: "¿Dónde encontrarnos?",
      subtitle: "Ante cualquier duda, no dudes en contactarnos",
      buttonLabel: "Mostrar ubicación",
    },
    footer: {
      aboutUs: "Acerca de nosotros",
      termsOfService: "Términos del servicio",
      privacyPolicy: "Política de privacidad",
      contactUs: "Contáctenos",
      booking: "Reserva",
      copyright:
        "Todos los derechos reservados a Reforma Dental, @2023 desarrollado por Ecommetrica.com",
    },
    stripe: {
      payment: "Confirmar la reservacion",
      name: "Nombre",
      email: "Correo electrónico",
      phone: "Teléfono",
      appointment: "Cita",
      price: "Precio",
      reserve: "Reservar",
      cancel: "Cancelar",
      download: "Descargar",
      loading: "Cargando...",
    },
    calendar: {
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      calendarDays: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      Days: ["D", "L", "M", "M", "J", "V", "S"],
      DayNotAvailable: "No hay horario disponible",
      DayAvailable: "Selecciona una hora",
    },
  },
};
