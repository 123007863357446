import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Components/Navbar";
import { silla } from "./Components/Images";
import { Parallax } from "react-parallax";
import HomeSection from "./Pages/HomeSection";
import MisionSection from "./Pages/MisionSection";
import SolutionSection from "./Pages/SolutionSection";
import TreatmetSection from "./Pages/TreatmetSection";
import BookingSection from "./Pages/Booking";
import ContactSection from "./Pages/ContactSection";
import FooterSection from "./Pages/FooterSection";
import { LanguageProvider } from "./Components/languageContext";

function Home() {
  return (
    <div className="App">
      <LanguageProvider>
        <Navbar />
        <HomeSection />
        <Parallax bgImage={silla} strength={500}>
          <div style={{ width: "80%", height: "100px" }}></div>
        </Parallax>
        <MisionSection />
        <SolutionSection />
        <Parallax bgImage={silla} strength={500}>
          <div style={{ width: "80%", height: "100px" }}></div>
        </Parallax>
        <TreatmetSection />
        <Parallax bgImage={silla} strength={500}>
          <div style={{ width: "80%", height: "100px" }}></div>
        </Parallax>
        <BookingSection />
        <ContactSection />
        <FooterSection />
      </LanguageProvider>
    </div>
  );
}

export default Home;
