import React, { useState } from "react";
import logo from "../img/logo.webp";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { TypeAnimation } from "react-type-animation";
import { products } from "../Components/products";
import mexico from "../img/Mexico.png";
import usa from "../img/USA.png";

function TreatmentPage() {
  const [language, setLanguage] = useState('en'); // Estado para el idioma actual

  const toggleLanguage = () => { // Función para cambiar el idioma
    setLanguage(language === 'es' ? 'en' : 'es');
  };

  return (
    <main className="mx-auto p-12 items-center justify-center flex flex-col">
      <div className="flex justify-center items-center gap-4">
      <img src={language === 'es' ? mexico : usa} alt="language" className="w-16" />
      <button onClick={toggleLanguage} className=" bg-green-500 hover:bg-green-700 border rounded-lg px-4 text-white">
        {language === 'es' ? 'Español' : 'English'}
      </button>
      </div>
      <div className="flex items-center py-4 gap-2">
        <a href="/">
          <img src={logo} alt="logo" className="w-12 h-12" />
        </a>
        <h1 className="text-green-500 text-4xl font-poppins font-light">
          <TypeAnimation
            cursor={true}
            sequence={[
              language === 'es' ? 'Reforma Dental' : 'Reforma Dental',
              1000
            ]}
            wrapper="span"
            speed={10}
          />
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 py-8 border-t border-green-200">
        {products.map((product) => (
          <Card key={product.id} className="w-72 shadow-xl shadow-green-100">
            <CardHeader shadow={false} floated={false}>
              <img
                src={product.image}
                alt={product.service}
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <div className="mb-2 flex items-center justify-around font-poppins font-medium text-xl">
                <span>
                  {language === 'es'
                    ? product.service.es
                    : product.service.en}
                </span>
                <span>{product.price}</span>
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex justify-center items-center">
                <a href={product.link}>
                  <Button className="bg-green-500 hover:bg-green-700">
                    <Typography color="white" className="font-bold font-poppins">
                      {language === 'es'
                        ? 'Ver tratamiento'
                        : 'Check Treatment'}
                    </Typography>
                  </Button>
                </a>
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    </main>
  );
}

export default TreatmentPage;
