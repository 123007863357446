export const articleSelecteds = [
    {
        href: "/blog/article",
        content: "Endodontics is commonly called killing the nerve. The procedure consists of removing the deep part of the tooth when it is injured or infected. The goal of this treatment is to clean the inside of the tooth and make a filling. This treatment can be used in case of pain or infection and is the last chance to save the tooth, because if it does not work, the only thing left to do is to remove the tooth completely.",
        content2: "Root canal treatment takes 1 to 2 visits to the dental office. It is a small and painless procedure, because the tooth uses internal anesthesia to prevent irritation. Once finished, the patient no longer thinks about the pain they felt before the procedure. An endodontist can check the soft tissues of the teeth. Using their knowledge and available treatments, endodontists can manage to treat various conditions in the teeth. They can also relieve pain and can try to rescue the patient's teeth.",
        content3: "This improves and extends the oral health of the patient. This technique is usually a recovery phase to avoid tooth extraction. If the dentist is not consulted immediately, the infection can damage the pulp of the tooth, which is located inside the tooth. If left untreated, the tissue around the root of the tooth or the gums can grow. If present, it can cause pain and swelling, and can form an abscess in the tooth, gum or bone at the base of that tooth or gum. This disease can affect the teeth or gums because bacteria can damage the bones that connect the jaw.",
        content4: "While root canals are an easy way to do the procedure, there are a few other ways to do obtain a better result. First, in our dental office, we perform a complete intraoral examination, we include the appropriate x-rays to make a detailed diagnosis and determine the appropriate care. After defining the method of application, we begin to care for the root. To do this, our experts must ensure that there is no inflammation or disease. In which case, antibiotics and anti-inflammatory drugs should be prescribed prior to the procedure. During root canal treatment, our teeth are exposed to the pulp in the second layer of the crown. The grafted flesh is removed, and the roots are cleaned. After cleaning the area, it is time to fill.",
        content5: "Oral hygiene, diet and regular dental visits promote good oral hygiene, as dental visits help to recover a beautiful and healthy white smile.",
        image1: "https://dentokmexico.com/wp-content/uploads/2023/02/Clinica-dental-en-Tijuana-2.jpg",
        image2: "https://cdn-3.expansion.mx/dims4/default/a7c223a/2147483647/strip/true/crop/2119x1414+0+0/resize/1800x1201!/format/webp/quality/80/?url=https%3A%2F%2Fcdn-3.expansion.mx%2F43%2F0b%2F5c794e0440d7aac99fb8e441bed1%2Fseguro-dental-que-cubre-cuanto-cuesta-y-como-usarlo-segun-la-profeco.jpg",
        image3: "https://clinicasden.com/wp-content/uploads/2022/04/que-es-obturacion-dental.jpg.webp",
    },
    {
        href: "/blog/document",
        content: "Viaje a lo Desconocido: Explorando Nuevas Fronteras",
        content2: "Explora nuevas ideas y descubre el mundo con nosotros. Únete a la aventura ahora.",
        content3: "Descubre mas sobre este articulo",
        content4: "Lugares increibles por descubrir",
        content5: "Explora el mundo con nosotros",
        image1: "https://source.unsplash.com/random",
        image2: "https://source.unsplash.com/random",
        image3: "https://source.unsplash.com/random",
    }
];
