import React from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Blog from './Blog';
import Contact from './Contact';
import Treatment from './Treatment';
import BlogDinamic from './BlogDinamic';
import TreatmentDinamic from './TreatmentDinamic';
function App() {
  return (
    
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDinamic />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/treatment" element={<Treatment />} />
          <Route path='/treatment/:id' element={<TreatmentDinamic />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;